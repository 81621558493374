import { LeadeboardWithPosition, Leaderboard } from 'types';

import RestApiService from './';

export default {
  getLeaderboardAllTime(this: RestApiService, lane: string) {
    return this.api.get<Leaderboard[]>(`/leaderboards/all-time`, {
      params: {
        lane,
      },
    });
  },
  getLeaderboardLastTen(this: RestApiService, lane: string) {
    return this.api.get<Leaderboard[]>(`/teams/recent`, {
      params: {
        lane,
      },
    });
  },
  getLeaderboardToday(this: RestApiService, lane: string) {
    return this.api.get<Leaderboard[]>(`/leaderboards/today`, {
      params: {
        lane,
      },
    });
  },
  getLeaderboardBv(this: RestApiService, lane: string) {
    return this.api.get<Leaderboard[]>(`/leaderboards/bv`, {
      params: {
        lane,
      },
    });
  },
  getLeaderboardLive(this: RestApiService, lane: string) {
    return this.api.get<Leaderboard[]>(`/teams/current`, {
      params: {
        lane,
      },
    });
  },
  getLeaderboardUser(this: RestApiService, lane: string) {
    return this.api.get<LeadeboardWithPosition[][]>(`/leaderboards/user`, {
      params: {
        lane,
      },
      authEnabled: true,
    });
  },
  getLeaderboardItemDetail(this: RestApiService, teamId: string) {
    return this.api.get<Leaderboard>(`/teams`, {
      params: {
        teamId,
      },
    });
  },
};
