import { AxiosError } from 'axios';
import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { Lane } from 'constants/room.constants';
import { prefetchLeaderboardAllTime } from 'queries/leaderboards/useLeaderboardAllTime';
import { prefetchLeaderboardBv } from 'queries/leaderboards/useLeaderboardBv';
import { prefetchLeaderboardLive } from 'queries/leaderboards/useLeaderboardLive';
import { prefetchLeaderboardToday } from 'queries/leaderboards/useLeaderboardToday';
import { prefetchLeaderboardUser } from 'queries/leaderboards/useLeaderboardUser';
import { checkForLogin, validateToken } from 'store/auth/auth.saga';
import { isStoredTokenValid } from 'store/auth/auth.selectors';

import { systemActions } from './system.slice';

function* initApp() {
  const isValid: SagaReturnType<typeof isStoredTokenValid> = yield select(
    isStoredTokenValid,
  );
  try {
    /**
     * start your saga flows
     */
    yield call(validateToken);
    yield call(checkForLogin);

    /**
     * system is ready -> time to render UI!
     */
    yield put(systemActions.SYSTEM_READY());
    yield call(prefetchLeaderboardAllTime, Lane.Omega);
    yield call(prefetchLeaderboardToday, Lane.Alpha);
    yield call(prefetchLeaderboardToday, Lane.Omega);
    yield call(prefetchLeaderboardBv, Lane.Alpha);
    yield call(prefetchLeaderboardBv, Lane.Omega);
    if (isValid) {
      yield call(prefetchLeaderboardUser, Lane.Alpha);
      yield call(prefetchLeaderboardUser, Lane.Omega);
    }
    yield call(prefetchLeaderboardLive, Lane.Alpha);
    yield call(prefetchLeaderboardLive, Lane.Omega);
  } catch (e: unknown) {
    yield put(systemActions.SYSTEM_FAIL(e as AxiosError<unknown>));
  }
}

export default function* () {
  yield all([takeLatest(systemActions.SYSTEM_START, initApp)]);
}
