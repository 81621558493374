import { Components, Theme } from '@mui/material';

export const MuiListItem: Components<Theme>['MuiListItem'] = {
  styleOverrides: {
    root: {
      borderRadius: 30,
      minHeight: 56,
      marginTop: 8,
    },
  },
};
