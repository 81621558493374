import { alpha, Components, Theme } from '@mui/material';

export const MuiListItemButton: Components<Theme>['MuiListItemButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 0,
      borderRadius: 30,
      marginTop: 8,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.8),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, 0.9),
      },
    }),
  },
};
