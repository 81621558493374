export const ONE_MINUTE = 60000;

export enum StaleTime {
  MINUTE = ONE_MINUTE,
  FIFTEEN_SECONDS = ONE_MINUTE / 4,
  FIVE_MIN = 5 * ONE_MINUTE,
  FIFTEEN_MIN = 15 * ONE_MINUTE,
  THIRTY_MIN = 30 * ONE_MINUTE,
  FORTY_FIVE_MIN = 45 * ONE_MINUTE,
  ONE_HOUR = 60 * ONE_MINUTE,
  FOREVER = Infinity,
}

export enum RefetchTime {
  MINUTE = ONE_MINUTE,
  FIVE_MIN = 5 * ONE_MINUTE,
  FIFTEEN_MIN = 15 * ONE_MINUTE,
  THIRTY_SECONDS = ONE_MINUTE / 2,
}

export enum CacheTime {
  MINUTE = ONE_MINUTE,
  FOREVER = Infinity,
}

export enum AlternateTime {
  THIRTY_SECONDS = ONE_MINUTE / 2,
  TWO_MIN = 2 * ONE_MINUTE,
  NINETY_SECONDS = ONE_MINUTE * 1.5,
}
