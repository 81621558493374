import axios, { AxiosRequestConfig } from 'axios';
import humps from 'humps';
import qs from 'query-string';

import store from 'store';
import { getAccessToken } from 'store/auth/auth.selectors';
import { getLocale } from 'store/intl/intl.selectors';

const DEFAULT_CONFIG: AxiosRequestConfig = {
  paramsSerializer: (params) => {
    return qs.stringify(humps.decamelizeKeys(params), { arrayFormat: 'comma' });
  },
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [
    (data) => {
      if (!(data instanceof FormData)) {
        // convert all body keys to snake_case
        data = humps.decamelizeKeys(data);

        // convert all body objects to strings
        if (typeof data === 'object') {
          data = JSON.stringify(data);
        }
      }

      return data;
    },
  ],
  transformResponse: [
    (data) => {
      try {
        // convert all body keys to camelCase
        if (typeof data !== 'object' && data) {
          data = JSON.parse(data);
        }
        if (!(data instanceof Blob)) {
          data = humps.camelizeKeys(data);
        }
        return data;
      } catch (error) {
        return humps.camelizeKeys(data);
      }
    },
  ],
};

const createManager = (requestConfig?: AxiosRequestConfig) => {
  const manager = axios.create({ ...DEFAULT_CONFIG, ...requestConfig });

  manager.interceptors.request.use(
    async (axiosConfig) => {
      const state = store.store.getState();

      const locale = getLocale(state);
      if (axiosConfig.headers) {
        axiosConfig.headers['Accept-Language'] = locale.toUpperCase();
      }

      const token = getAccessToken(state);

      if (token && axiosConfig.authEnabled) {
        if (axiosConfig.headers) {
          axiosConfig.headers['Authorization'] = `Bearer ${token}`;
        }
      }

      return axiosConfig;
    },
    (error) => Promise.reject(error),
  );

  return manager;
};

export { createManager };
