import { Box, useTheme } from '@mui/material';

import bgImage from 'assets/images/bg.jpg';
import bgImageLarge from 'assets/images/bg@2x.jpg';
import bgTvImage from 'assets/images/bg-tv.jpg';

const BackgroundImage = () => {
  const theme = useTheme();
  return (
    <Box
      position="absolute"
      p={0}
      left={0}
      right={0}
      height="100%"
      minHeight="100vh"
      zIndex={-1}
      sx={{
        backgroundImage: theme.isTv
          ? `url(${bgTvImage})`
          : {
              xs: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 15.1%, rgba(0, 0, 0, 0) 100%), url(${bgImage})`,
              lg: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 15.1%, rgba(0, 0, 0, 0) 100%), url(${bgImageLarge})`,
            },

        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
      }}
    />
  );
};

export default BackgroundImage;
