import { FC } from 'react';
import { Route } from 'react-router-dom';

import { Route as RouteProps } from 'pages/routes.types';

const RoutePublic: FC<RouteProps> = ({ authenticated, ...props }) => (
  <Route {...props} />
);

export default RoutePublic;
