import { getTokenSub, isTokenValid } from 'utils/token.utils';

import { Store } from 'store/reducers';

export const isStoredTokenValid = (state: Store) => {
  const token = state.auth.accessToken;
  return isTokenValid(token);
};

export const getAccessToken = (state: Store) => {
  return state.auth.accessToken;
};

export const getAuthLoading = (state: Store) => {
  return state.auth.loading;
};

export const getUserId = (state: Store) => {
  return getTokenSub(state.auth.accessToken);
};

export const getRedirectRoute = (state: Store) => {
  return state.auth.redirectRoute;
};
