import { Components, Theme } from '@mui/material';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 48,
      color: theme.palette.text.primary,
      border: '2px solid #FFFFFF',
      backgroundColor: 'transparent',
    }),
  },
};
