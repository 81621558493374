import { Components, Theme } from '@mui/material';

export const MuiStepConnector: Components<Theme>['MuiStepConnector'] = {
  styleOverrides: {
    root: {
      margin: 0,
      marginBottom: -30,
      marginTop: -30,
    },
    line: ({ theme }) => ({
      border: 0,
      width: 4,
      marginTop: 16,
      backgroundColor: theme.palette.secondary.main,
      minHeight: '36px',
    }),
  },
};
