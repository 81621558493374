import { LeadeboardWithPosition } from 'types';

import RestApiService from './';

export default {
  searchLeaderboard(this: RestApiService, lane: string, name: string) {
    return this.api.get<LeadeboardWithPosition[]>(`/teams/search`, {
      params: {
        lane,
        name,
      },
    });
  },
};
