import config from 'config';

import { ShareResponse } from 'types/share.types';

import RestApiService from './';

export default {
  shareLeaderboard(this: RestApiService, teamId: string) {
    return this.api.post<ShareResponse>(
      `${config.SHARE_BASE}/share/score/${teamId}`,
    );
  },
};
