import { Components, Theme } from '@mui/material';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: {
      borderRadius: 4,
    },
    indicator: {
      height: '100%',
      borderRadius: 4,
      zIndex: -1,
    },
  },
};
