export enum Lane {
  Alpha = 'alpha',
  Omega = 'omega',
}

export const ROOM_NAMES: { [key1: string]: { [key2: string]: string } } = {
  '1': { [Lane.Alpha]: 'Cinema', [Lane.Omega]: 'Himalaya' },
  '2': { [Lane.Alpha]: 'Visserij', [Lane.Omega]: 'Kerstmis' },
  '3': { [Lane.Alpha]: 'Geitenboerderij', [Lane.Omega]: 'Ali Baba' },
  '4': { [Lane.Alpha]: 'Middeleeuwen', [Lane.Omega]: 'Brandweer' },
  '5': { [Lane.Alpha]: 'Garage', [Lane.Omega]: 'Frituur' },
};
export const ROOM_LENGTH = 5;
