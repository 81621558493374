import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager';

import config from 'config';

import { DATALAYER_NAME } from 'types/analytics.types';

export const gtmParams: ISnippetsParams = {
  id: config.ANALYTICS.GTM_ID,
  dataLayer: {},
  dataLayerName: DATALAYER_NAME,
  injectScript: !!config.ANALYTICS.GTM_ID.length ? true : false,
};
