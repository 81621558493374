import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

interface SystemState {
  booting: boolean;
  ready: boolean;
  error?: AxiosError;
}

const initialState: SystemState = {
  booting: false,
  ready: false,
  error: undefined,
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    SYSTEM_START: (state) => {
      state.booting = true;
      state.error = undefined;
    },
    SYSTEM_READY: (state) => {
      state.booting = false;
      state.ready = true;
    },
    SYSTEM_FAIL: (state, { payload }: PayloadAction<SystemState['error']>) => {
      state.error = payload;
    },
  },
});

export const { actions: systemActions } = systemSlice;

export default systemSlice.reducer;
