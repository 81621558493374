import React, { Suspense } from 'react';
import { Router, Switch } from 'react-router-dom';

import { historyService } from 'services';

import RouteBoundary from 'components/@boundaries/RouteBoundary';
import { RoutePublic } from 'components/@common/Route';

import routes from './routes';

const NotFound = React.lazy(
  () => import('./not-found' /* webpackChunkName: "not-found" */),
);

const Pages = () => (
  <RouteBoundary>
    <Router history={historyService.history}>
      <Suspense fallback={null}>
        <Switch>
          {routes.map((route) => {
            const routeProps = {
              exact: route.exact,
              path: route.path,
              component: route.component,
              authenticated: route.authenticated,
            };

            return <RoutePublic key={route.path?.toString()} {...routeProps} />;
          })}
          <RoutePublic component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  </RouteBoundary>
);

export default Pages;
