import jwtDecode, { JwtPayload } from 'jwt-decode';

export function isTokenValid(token?: string) {
  try {
    if (token) {
      const { exp } = jwtDecode<JwtPayload>(token);
      return exp ? exp > (new Date().getTime() + 1) / 1000 : false;
    } else {
      return false;
    }
  } catch {
    return false;
  }
}

export function getTokenSub(token?: string) {
  try {
    if (token) {
      const { sub } = jwtDecode<JwtPayload>(token);
      return sub;
    } else {
      return undefined;
    }
  } catch {
    return undefined;
  }
}
