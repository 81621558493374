import { AxiosInstance } from 'axios';

import authApi from './auth.api';
import leaderboardApi from './leaderboard.api';
import searchApi from './search.api';
import shareApi from './share.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // AUTH
  public getAuth = authApi.getAuth;

  // Leaderboard
  public getLeaderboardAllTime = leaderboardApi.getLeaderboardAllTime;
  public getLeaderboardToday = leaderboardApi.getLeaderboardToday;
  public getLeaderboardBv = leaderboardApi.getLeaderboardBv;
  public getLeaderboardItemDetail = leaderboardApi.getLeaderboardItemDetail;
  public getLeaderboardUser = leaderboardApi.getLeaderboardUser;
  public getLeaderboardLive = leaderboardApi.getLeaderboardLive;
  public getLeaderboardLastTen = leaderboardApi.getLeaderboardLastTen;

  // SHARE
  public shareLeaderboard = shareApi.shareLeaderboard;

  // Search
  public searchLeaderboard = searchApi.searchLeaderboard;
}

export default RestApiService;
