import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { CacheTime, RefetchTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { queryClient } from 'services/react-query';
import { LeadeboardWithPosition } from 'types';

const fetchLeaderboard = async (lane: string) => {
  const { data: response } = await apiService.getLeaderboardUser(lane);
  return response;
};

export const useLeaderboardUser = (
  lane: string,
  config: UseCommonQueryOptions<LeadeboardWithPosition[][]> = {},
) => {
  const { data, status, isLoading, error } = useQuery(
    QueryKeys.leaderboards.user(lane),
    () => fetchLeaderboard(lane),

    {
      ...config,
      refetchInterval: RefetchTime.THIRTY_SECONDS,
      cacheTime: config?.cacheTime ?? CacheTime.FOREVER,
    },
  );

  return {
    data,
    status,
    isLoading,
    error,
  };
};

export const prefetchLeaderboardUser = async (lane: string) => {
  try {
    await queryClient.prefetchQuery(QueryKeys.leaderboards.user(lane), () =>
      fetchLeaderboard(lane),
    );
  } catch {}
};
