import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    contained: ({ theme }) => ({
      borderRadius: 48,
      backgroundColor: theme.palette.global03[400],
      '&:hover': {
        backgroundColor: theme.palette.global03[500],
      },
      '&:active': {
        backgroundColor: theme.palette.global03[600],
      },
      '& #buttonSpan': {
        transform: 'translateY(3px)',
      },
    }),
    outlined: ({ theme }) => ({
      borderRadius: 48,
      color: theme.palette.text.primary,
      border: '2px solid #FFFFFF',
      backgroundColor: 'transparent',
      '&:hover': {
        color: theme.palette.text.primary,
        border: '2px solid #FFFFFF',
      },
      '&:active': {
        color: theme.palette.text.primary,
        border: '2px solid #FFFFFF',
      },
      '& #buttonSpan': {
        transform: 'translateY(3px)',
      },
    }),

    root: ({ ownerState, theme }) => ({
      ...(ownerState.variant === 'vtm' && {
        fontFamily: 'Nunito',
        fontWeight: 800,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: 30,
        textTransform: 'capitalize',
        background: 'linear-gradient(92.12deg, #D25000 2.33%, #FF6E00 97.57%)',
        padding: theme.spacing(1, 4),
        '&:hover': {
          opacity: 0.8,
        },
      }),
    }),
  },
};
