/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import home from './home';
import lastTen from './last-ten';
import loginCallback from './login-callback';
import roomBoth from './room-both';
import roomMathias from './room-mathias';
import roomStaf from './room-staf';
import { Route } from './routes.types';

export default [
  home,
  loginCallback,
  roomMathias,
  roomStaf,
  roomBoth,
  lastTen,
] as Route[];
