enum Keys {
  User = 'user',
  LeaderboardAllTime = 'leaderboardAllTime',
  LeaderboardLastTen = 'leaderboardLastTen',
  LeaderboardToday = 'leaderboardToday',
  LeaderboardBv = 'leaderboardBv',
  LeaderboardItemDetail = 'leaderboardItemDetail',
  LeaderboardUser = 'leaderboardUser',
  LeaderboardLive = 'leaderboardLive',
  Search = 'search',
}

export const QueryKeys = {
  users: {
    byId: (...rest: unknown[]) => [Keys.User, ...rest],
  },
  leaderboards: {
    allTime: (lane: string) => [Keys.LeaderboardAllTime, lane],
    lastTen: (lane: string) => [Keys.LeaderboardLastTen, lane],
    today: (lane: string) => [Keys.LeaderboardToday, lane],
    bv: (lane: string) => [Keys.LeaderboardBv, lane],
    user: (lane: string) => [Keys.LeaderboardUser, lane],
    live: (lane: string) => [Keys.LeaderboardLive, lane],
    byId: (...rest: unknown[]) => [Keys.LeaderboardItemDetail, ...rest],
  },
  search: {
    team: (lane: string, teamName: string) => [Keys.Search, lane, teamName],
  },
};
