import { Components } from '@mui/material';

import VeneerWoff from 'assets/fonts/Veneer.woff';
import VeneerWoff2 from 'assets/fonts/Veneer.woff2';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: {
    body: {
      position: 'relative',
    },
    '@font-face': {
      fontFamily: 'Veneer',
      fontWeight: 400,
      src: `local('Veneer'), url(${VeneerWoff}) format('woff'), url(${VeneerWoff2}) format('woff2')`,
    },
  },
};
