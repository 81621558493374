import { createTheme } from '@mui/material/styles';

/**
 *   Setup base theme allows to be imported at other places (ex, palette, typography, spacing,...)
 */
export const baseTv = createTheme({
  isTv: true,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: { overflowY: 'hidden' },
      },
    },
  },
  palette: {
    primary: {
      main: '#093349',
    },
    secondary: {
      main: '#52A5D8',
    },
    global01: {
      400: '#093349',
    },
    global02: {
      300: '#093349',
      400: '#52A5D8',
    },
    global03: {
      400: '#DBAF14',
      500: '#B38C0A',
      600: '#8A6900',
    },
    medal: {
      400: '#FFB800',
      500: '#C8C8C8',
      600: '#BB7E2A',
    },
    text: {
      primary: '#fff',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Nunito, sans-serif',
    fontWeightRegular: 500,
    fontWeightLight: 500,
    fontWeightBold: 800,
    fontWeightMedium: 700,
    h1: {
      fontFamily: 'Veneer, sans-serif',
      fontWeight: 400,
      fontSize: '4rem',
    },
    h2: {
      fontFamily: 'Veneer, sans-serif',
      fontWeight: 400,
      fontSize: '3rem',
    },
    h3: {
      fontFamily: 'Veneer, sans-serif',
      fontWeight: 400,
      fontSize: '4rem',
    },
    h4: {
      fontFamily: 'Veneer, sans-serif',
      fontWeight: 400,
      fontSize: '2.5rem',
    },
    h5: {
      fontFamily: 'Veneer, sans-serif',
      fontWeight: 400,
      fontSize: '1.75rem',
    },
    h6: {
      fontFamily: 'Veneer, sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
    },
    button: {
      fontFamily: 'Veneer, sans-serif',
      fontWeight: 400,
      fontSize: '1.875rem',
    },
    body1: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
    },
    body2: {
      fontSize: '1.75rem',
      lineHeight: '1.75rem',
    },
    caption: {
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
    },
  },
});
