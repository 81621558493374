import { FC } from 'react';
import { RawIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { getLocale } from 'store/intl/intl.selectors';

import { intl } from './config';

const IntlSelectorProvider: FC = ({ children }) => {
  const locale = useSelector(getLocale);

  return (
    <RawIntlProvider key={locale} value={intl[locale]}>
      {children}
    </RawIntlProvider>
  );
};

export default IntlSelectorProvider;
