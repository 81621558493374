import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { CacheTime, RefetchTime, StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { queryClient } from 'services/react-query';
import { Leaderboard } from 'types';

const fetchLeaderboard = async (lane: string) => {
  const { data: response } = await apiService.getLeaderboardLive(lane);
  return response;
};

export const useLeaderboardLive = (
  lane: string,
  config: UseCommonQueryOptions<Leaderboard[]> = {},
) => {
  const { data, status, isLoading, error } = useQuery(
    QueryKeys.leaderboards.live(lane),
    () => fetchLeaderboard(lane),
    {
      ...config,
      refetchInterval: RefetchTime.THIRTY_SECONDS,
      staleTime: config?.staleTime ?? StaleTime.FIFTEEN_SECONDS,
      cacheTime: config?.cacheTime ?? CacheTime.FOREVER,
    },
  );

  return {
    data,
    status,
    isLoading,
    error,
  };
};

export const prefetchLeaderboardLive = async (lane: string) => {
  try {
    await queryClient.prefetchQuery(QueryKeys.leaderboards.live(lane), () =>
      fetchLeaderboard(lane),
    );
  } catch {}
};
