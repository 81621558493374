import { Components, Theme } from '@mui/material';

export const MuiStepper: Components<Theme>['MuiStepper'] = {
  styleOverrides: {
    root: {
      padding: 0,
      height: '100%',
      alignItems: 'center',
    },
  },
};
