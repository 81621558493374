import { createTheme } from '@mui/material/styles';

import { baseTheme } from './base';
import * as components from './overrides';

/**
 *  Theme
 */
const theme = createTheme(baseTheme, { components });

export default theme;
