import { Components, Theme } from '@mui/material';

export const MuiStepLabel: Components<Theme>['MuiStepLabel'] = {
  styleOverrides: {
    root: {
      alignItems: 'center',
    },
    iconContainer: {
      padding: 0,
    },
  },
};
