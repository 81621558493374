// comment out locales that should not be supported
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/be';

import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import config from 'config';

import MESSAGES_NL from './translations/nl-BE.json';

const { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE } = config;

const locales = ['nl-BE'];

const intlCache = createIntlCache();

const intl: { [key: string]: IntlShape } = {
  'nl-BE': createIntl({ locale: 'nl-BE', messages: MESSAGES_NL }, intlCache),
};

export { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE, intl, locales };
