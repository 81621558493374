import { isProd } from 'utils/env.utils';

export interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  BUILD_ENV: string;
  API_BASE: string;
  SHARE_BASE: string;
  CLIENT_URL: string;
  IFRAME_CLIENT_URL: string;
  SERVER_URL: string;
  PORT?: number;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
    tracesSampleRate: number;
    sampleRate: number;
  };
  VTM_CLIENT_ID: string;
  ANALYTICS: {
    GTM_ID: string;
  };
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',
  BUILD_ENV: process.env.REACT_APP_BUILD_ENV || '',

  // api
  SHARE_BASE: process.env.REACT_APP_SHARE_BASE || '',
  API_BASE: process.env.REACT_APP_API_BASE || '',
  CLIENT_URL: process.env.REACT_APP_CLIENT_URL || '',
  IFRAME_CLIENT_URL: process.env.REACT_APP_IFRAME_CLIENT_URL || '',
  SERVER_URL: process.env.REACT_APP_SERVER_URL || '',

  // error tracking
  SENTRY: {
    dsn: 'https://2aaf3ed04ee04fee9b584dd52598ad04@o190235.ingest.sentry.io/4503942340149248',
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
    tracesSampleRate: isProd() ? 0.2 : 1.0,
    sampleRate: isProd() ? 0.25 : 1.0,
  },

  // TODO: @yannis change
  VTM_CLIENT_ID: 'vtm-cvc-web',

  // analytics
  ANALYTICS: {
    GTM_ID: process.env.REACT_APP_TAG_MANAGER_ID ?? '',
  },

  // dev
  PORT: Number(process.env.REACT_APP_PORT ?? 3000),
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? 'nl-BE',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? 'nl',
};

export default config;
