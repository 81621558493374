import * as Sentry from '@sentry/react';
import { FC } from 'react';

import { FallbackProps } from './boundary.types';

// Since nothing is loaded at this point the fallback can only return basic HTML
// Do not attempt to translate this error or add styles, they are not loaded yet!
const Fallback: FC<FallbackProps> = ({ error, componentStack }) => {
  const message = error?.message;
  return (
    <>
      <h1>Whoopsie</h1>
      {message && <p>{message}</p>}
      <pre>{componentStack}</pre>
    </>
  );
};

// This is the everything else failed fallback and is normally never shown to the user
// If there is an issue with the connectors then this will make sure we see it in sentry
const RootBoundary: FC = ({ children }) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => scope.setTag('boundary', 'root')}
    fallback={(props) => <Fallback {...props} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default RootBoundary;
