import { Components, Theme } from '@mui/material';

export const MuiPopover: Components<Theme>['MuiPopover'] = {
  styleOverrides: {
    root: {
      '& .MuiPopover-paper': {
        marginTop: 40,
        overflow: 'visible',
      },
    },
  },
};
