import { alpha, Components, tabClasses, Theme } from '@mui/material';

export const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 4,
      marginRight: 0,
      minWidth: 80,
      [theme.breakpoints.up('md')]: {
        marginRight: 8,
      },
      maxWidth: 'none',
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      padding: '8px 10px',
      '&:hover': {
        backgroundColor: alpha(theme.palette.global01[400], 0.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.global01[400], 0.8),
      },

      [`&.${tabClasses.selected}`]: {
        color: theme.palette.text.primary,
        textShadow: `0px 1px 10px ${theme.palette.text.primary}`,
      },

      [`&:not(.${tabClasses.selected})`]: {
        '&& svg': {
          opacity: 0.5,
        },
      },

      [`&.${tabClasses.labelIcon}`]: {
        minHeight: 0,
      },
    }),
  },
};
