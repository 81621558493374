import { History } from 'history';

class HistoryService {
  private offset = 0;
  private scrollOnResize = false;
  public history: History;

  constructor(history: History) {
    this.history = history;

    const parentIframe = window.parentIFrame;

    if (parentIframe) {
      parentIframe.setHeightCalculationMethod('lowestElement');
    }
  }

  public push(route: string) {
    const parentIframe = window.parentIFrame;

    if (parentIframe) {
      parentIframe.getPageInfo(({ scrollTop }) => {
        this.offset = scrollTop;
        parentIframe.getPageInfo(false);
        this.history.push(route);
        this.scrollOnResize = false;
        setTimeout(() => {
          parentIframe.scrollToOffset(0, 0); // Scroll to top
        }, 100);
      });
    } else {
      this.history.push(route);
      window.scrollTo(0, 0);
    }
  }

  public goBack() {
    const parentIframe = window.parentIFrame;

    if (parentIframe) {
      this.scrollOnResize = true;
    }

    this.history.goBack();
  }

  public replace(path: string) {
    const parentIframe = window.parentIFrame;

    if (parentIframe) {
      this.scrollOnResize = true;
    }

    this.history.replace(path);
  }

  public goHome() {
    const parentIframe = window.parentIFrame;

    if (parentIframe) {
      this.scrollOnResize = true;
    }

    this.history.push('/');
  }

  public goBackFromStack(lastVisitedUrls: string[]) {
    const parentIframe = window.parentIFrame;

    if (parentIframe) {
      this.scrollOnResize = true;
    }

    if (lastVisitedUrls.length >= 2) {
      this.history.push(lastVisitedUrls[lastVisitedUrls.length - 2]);
    } else {
      this.history.push('/');
    }
  }

  public finishedResize() {
    const parentIframe = window.parentIFrame;

    if (parentIframe && this.scrollOnResize) {
      const parentIframe = window.parentIFrame;
      setTimeout(() => {
        if (parentIframe) {
          parentIframe.scrollTo(0, this.offset);
        }
      }, 100);
    }
  }
}

export default HistoryService;
