export enum EventName {
  Detail = 'detail',
}

export enum PageView {
  MathiasHome = 'mathias/home',
  StafHome = 'staf/home',
  MathiasToday = 'mathias/today',
  StafToday = 'staf/today',
  MathiasLive = 'mathias/live',
  StafLive = 'staf/live',
  MathiasMyScore = 'mathias/my-score',
  StafMyScore = 'staf/my-score',
  MathiasBv = 'mathias/bv',
  StafBv = 'staf/bv',
}

export const DATALAYER_NAME = 'dataLayer';
