import type {} from '@mui/lab/themeAugmentation';
import { Components, Theme } from '@mui/material';

export const MuiTabPanel: Components<Theme>['MuiTabPanel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: '0px 16px 0px 0px',
      minHeight: 640,
      [theme.breakpoints.up('md')]: {
        maxWidth: 760,
        paddingRight: 3,
      },
    }),
  },
};
