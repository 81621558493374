import { createTheme } from '@mui/material/styles';

import { baseTv } from './baseTv';
import * as components from './overrides';

/**
 *  Theme
 */
const tvTheme = createTheme(baseTv, { components });

export default tvTheme;
